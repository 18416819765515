<template>
  <PageWrapper class="bg-gray-100">
    <div
      class="flex flex-col justify-center items-center max-w-4xl"
      v-if="selectedForms.length"
    >
      <div
        class="w-full flex flex-col justify-center items-center forms-font"
        v-for="form in selectedForms"
        :key="form.formId"
      >
        <div
          v-for="page in form.pages"
          :key="page.pageNumber"
          class="shadow-2xl mb-2 relative form-font"
        >
          <img :src="page.fullPath" alt="" />
          <div
            v-for="field in page.fields"
            :key="field.formFieldId"
            class="absolute"
            :style="{
              bottom: `${(field.lly / page.pageHeight) * 100}%`,
              left: `${(field.llx / page.pageWidth) * 100}%`,
              width: `${((field.urx - field.llx) / page.pageWidth) * 100}%`,
              height: `${((field.ury - field.lly) / page.pageHeight) * 100}%`,
            }"
          >
            <input
              type="checkbox"
              name=""
              id=""
              v-model="field.value"
              class="w-full h-full cursor-pointer bg-yellow-100"
              v-if="field.fieldType === formFieldTypes.checkbox"
            />
            <input
              type="radio"
              name=""
              id=""
              v-model="field.value"
              class="w-full h-full cursor-pointer bg-yellow-100"
              v-else-if="field.fieldType === formFieldTypes.radio"
            />
            <input
              type="text"
              name=""
              id=""
              v-model="field.value"
              class="w-full h-full bg-yellow-100 px-1"
              v-else-if="field.fieldType === formFieldTypes.text"
            />
            <div
              class="w-full h-full flex justify-center items-center"
              v-else-if="field.fieldType === formFieldTypes.signature"
            >
              <button
                class="w-12 h-12 bg-yellow-300 text-xs flex flex-col justify-center items-center uppercase font-primary"
                :id="field.formFieldId"
                @click="signDocument(field.formFieldId)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="ai ai-ArrowDown hidden sm:block"
                >
                  <path d="M12 20V4" />
                  <path d="M5 13l7 7 7-7" />
                </svg>
                Sign
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full flex justify-center items-center" v-else>
      <i class="fas fa-lg fa-circle-notch fa-spin"></i>
    </div>
  </PageWrapper>
</template>

<script>
import { reactive, toRefs } from "@vue/reactivity";
import { formFieldTypes } from "../../common/constants";
import PageWrapper from "../../components/home/layout/PageWrapper.vue";
import useBenefitRecord from "../../composables/useBenefitRecord";
export default {
  components: {
    PageWrapper,
  },
  setup() {
    const { selectedForms } = useBenefitRecord();

    const state = reactive({
      currentFormIndex: 0,
    });

    const nextForm = () => {
      state.currentFormIndex =
        (state.currentFormIndex + 1) % selectedForms.value.length;
    };

    const previousForm = () => {
      state.currentFormIndex =
        state.currentFormIndex === 0
          ? selectedForms.value.length - 1
          : state.currentFormIndex - 1;
    };

    const signDocument = (formFieldId) => {
      const currentSignatureField = document
        .getElementById(formFieldId)
        .getBoundingClientRect();
      const allSignatureFields = [];
      selectedForms.value.forEach((form) => {
        form.pages.forEach((page) => {
          page.fields.forEach((field) => {
            if (field.fieldType === formFieldTypes.signature)
              allSignatureFields.push(field.formFieldId);
          });
        });
      });
      if (
        allSignatureFields.indexOf(formFieldId) + 1 <
        allSignatureFields.length
      ) {
        const nextSignatureFieldId =
          allSignatureFields[allSignatureFields.indexOf(formFieldId) + 1];
        const nextSignatureField = document
          .getElementById(nextSignatureFieldId)
          .getBoundingClientRect();
        const pageWrapper = document.getElementById("page-wrapper");
        pageWrapper.scrollTop += nextSignatureField.y - currentSignatureField.y;
      }
    };

    return {
      formFieldTypes,
      selectedForms,
      ...toRefs(state),
      nextForm,
      previousForm,
      signDocument,
    };
  },
};
</script>

<style scoped>
.forms-font {
  font-family: "Times New Roman", Times, serif;
}
</style>
